export const recipeData = {
  1: {
    name: "Garlic Mustard",
    scientificName: "Alliaria petiolata",
    images: {
      plant: "/images/garlic_mustard_plant.png",
      color: "/images/garlic_mustard_color.png",
    },
    details: [
      ["condition", "fresh"],
      ["parts", "leaves and stems"],
      ["pH", "9"],
      ["ratio", "2:1"],
      ["temp", "180F"],
      ["extract time", "1 hr"],
      ["dye time", "1 hr"],
    ],
    identification:
      "Garlic mustard can be detected by sight and smell. The leaves are broad with scalloped edges. In springtime they produce small white flowers. The smell is very similar to garlic.",
    batchNotes: "Used leaves and stems 24 hours after harvesting.",
  },
  2: {
    name: "Avocado",
    scientificName: "Persea americana",
    images: {
      plant: "/images/avocado_plant.jpg",
      color: "/images/avocado_color.jpg",
    },
    details: [
      ["condition", "fresh"],
      ["parts", "pits"],
      ["pH", "9"],
      ["ratio", "3:1"],
      ["temp", "160F"],
      ["extract time", "1 hr / 4 days"],
      ["dye time", "1 hr"],
    ],
    identification:
      "Garlic mustard can be detected by sight and smell. The leaves are broad with scalloped edges. In springtime they produce small white flowers. The smell is very similar to garlic.",
    batchNotes:
      "Turned heat on for 1 hour a day for 4 days and let cool back down to room temp. Ratio is 3 pits to 1 skein vs. by weight. But the more pits the better!",
  },
  3: {
    name: "English Ivy",
    scientificName: "Hedera helix",
    images: {
      plant: "/images/english_ivy_plant.jpg",
      color: "/images/english_ivy_color.jpg",
    },
    details: [
      ["condition", "fresh"],
      ["parts", "leaves and stems"],
      ["pH", "9"],
      ["ratio", "3:1"],
      ["temp", "180F"],
      ["extract time", "1 hr"],
      ["dye time", "1 hr"],
    ],
    identification:
      "Garlic mustard can be detected by sight and smell. The leaves are broad with scalloped edges. In springtime they produce small white flowers. The smell is very similar to garlic.",
    batchNotes:
      "Used just leaves immediately after harvest. Can sometimes be waxy and require top end of heat range at the very beginning of extract time",
  },
  4: {
    name: "Scotch Broom",
    scientificName: "Cytisus scoparius",
    images: {
      plant: "/images/scotch_broom_plant.jpg",
      color: "/images/scotch_broom_color.jpg",
    },
    details: [
      ["condition", "fresh or frozen"],
      ["parts", "flowers"],
      ["pH", "9"],
      ["ratio", "3:1"],
      ["temp", "160F"],
      ["extract time", "1 hr"],
      ["dye time", "1 hr"],
    ],
    identification:
      "Garlic mustard can be detected by sight and smell. The leaves are broad with scalloped edges. In springtime they produce small white flowers. The smell is very similar to garlic.",
    batchNotes:
      "Froze flowers for a couple weeks. Leaves and stems can also be used",
  },
  5: {
    name: "Dandelion",
    scientificName: "Taraxacum",
    images: {
      plant: "/images/dandelion_plant.jpg",
      color: "/images/dandelion_color.jpg",
    },
    details: [
      ["condition", "fresh or frozen"],
      ["parts", "flowers"],
      ["pH", "9"],
      ["ratio", "3:1"],
      ["temp", "180F"],
      ["extract time", "1.5 hr"],
      ["dye time", "1 hr"],
    ],
    identification:
      "Garlic mustard can be detected by sight and smell. The leaves are broad with scalloped edges. In springtime they produce small white flowers. The smell is very similar to garlic.",
    batchNotes:
      "Froze flowers for a couple weeks. Leaves and stems can also be used",
  },
  // Add more recipes here...
};
