import React from "react";
import RetroWindowWrapper from "./RetroWindowWrapper";
import DottedBox from "./DottedBox";

const ProcessPage = () => {
  return (
    <RetroWindowWrapper activeTab="Process">
      <div className="p-4 overflow-y-auto h-[calc(100%-2rem)]">
        <DottedBox title="Our Process">
          <ol className="list-decimal list-inside text-sm space-y-4">
            <li>Coming Soon!</li>
          </ol>
        </DottedBox>
      </div>
    </RetroWindowWrapper>
  );
};

export default ProcessPage;
