import React from "react";
import RetroWindowWrapper from "./RetroWindowWrapper";
import DottedBox from "./DottedBox";

const AboutPage = () => {
  return (
    <RetroWindowWrapper activeTab="About">
      <div className="p-4 overflow-y-auto h-[calc(100%-2rem)]">
        <DottedBox title="Artist Statement">
          <p className="text-sm mb-4">
            My work explores the modern relationship between humans and native
            ecosystems. Both intentionally and inadvertently, human movement has
            introduced invasive species into new environments. Often these
            species were brought for their inherit value: Garlic Mustard is a
            delicious cooking herb, English Ivy is a beautiful material to make
            soap. Many of our introduced plants thrive and out-compete native
            plants, through no fault of their own. And now both we and our
            native landscapes coexist with them in a new relationship. We walk
            by hundreds of species of plants in our daily routines, not knowing
            their names or their native homes. We have no ability to recognize a
            native plant from an invasive one, green is green.
          </p>
          <p className="text-sm">
            To the viewer of my work, I hope to help you form a new relationship
            with these invasive plants. I hope you come to know them by name, by
            use, and by their removal protocol. <br></br>
          </p>
          <p className="text-sm">
            Environmental restoration of native ecosystems is essential to
            protecting biodiversity. And biodiversity is essential to a
            climate-change resilient environment. How can we encourage
            responsibility for environmental restoration? And can we bring joy
            and curiosity to the process?
          </p>
        </DottedBox>
      </div>
    </RetroWindowWrapper>
  );
};

export default AboutPage;
